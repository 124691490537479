<template>
    <section-block class="hero base-background">
        <h2 class="mb-8">Get connected and learn more about partnering with us.</h2>
        <form class="contact-form" @submit.prevent="submitForm">
            <div class="row">
                <div class="col-12 col-md-5 form-group">
                    <label for="firstName">First name</label>
                    <input type="text" id="firstName" v-model="firstName" required />
                </div>
                <div class="col-12 col-md-1" />
                <div class="col-12 col-md-5 form-group">
                    <label for="lastName">Last name</label>
                    <input type="text" id="lastName" v-model="lastName" required />
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-5 form-group">
                    <label for="email">Email</label>
                    <input type="email" id="email" v-model="email" required />
                </div>
                <div class="col-12 col-md-1" />
                <div class="col-12 col-md-5 form-group">
                    <label for="phoneNumber">Phone</label>
                    <input type="number" id="phoneNumber" v-model="phoneNumber" required />
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-5 form-group">
                    <label for="company">Company</label>
                    <input type="text" id="company" v-model="company" required />
                </div>
                <div class="col-12 col-md-1" />
                <div class="col-12 col-md-5 form-group">
                    <label for="subject">Subject</label>
                    <input type="text" id="subject" v-model="subject" required />
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-5 form-group">
                    <label for="message">Message</label>
                    <input type="text" id="message" v-model="message" required />
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-5">
                    <button type="submit" class="button-dark">Submit Form</button>
                </div>
            </div>
        </form>
    </section-block>
</template>

<script>
    import SectionBlock from '@/components/SectionBlock'
    // import { sendEmail } from '@/sendGridApi'
    export default {
        name: 'Contact',
        components: { SectionBlock },
        data() {
            return {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: null,
                company: '',
                subject: '',
                message: '',
            }
        },
        methods: {
            async submitForm() {
                window.open(
                    `mailto:Sam.Kramer@desquw.com?subject=${this.subject}&body=${this.message} | Name: ${this.firstName} ${this.lastName} | Company: ${this.company} | Phone: ${this.phoneNumber} | Email: ${this.email}`
                )
            },
        },
    }
</script>

<style scoped></style>
